/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import GlobalStyles from "./global-styles"
import Menu from "./menu"
import POTGBanner from "./potg-banner"

function Layout({ children }) {
  return (
    <>
      <GlobalStyles />
      <POTGBanner />
      <Menu />
      {children}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
