import { Link } from "gatsby"
import styled, { css } from "styled-components"

const linkStyle = css`
  text-decoration: none;

  padding-top: 0.25rem;
  padding-bottom: 0.5rem;
  color: white;

  &:hover {
    ${props =>
      props.disabled
        ? `
      cursor: default;
      pointer-events: none;
      color: ${props => props.color};
    `
        : `
      cursor: pointer;
      color: ${props => props.color};
      opacity: 0.8;
    `}
  }

  ${props => props.disabled && "opacity: 0.5;"}
`

export const DefaultLink = styled.a`
  ${linkStyle}
`

export const GatsbyLink = styled(Link)`
  ${linkStyle}
`
