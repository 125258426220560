import React from 'react'

const SvgComponent = props => (
  <svg width="1em" height="1em" viewBox="0 0 72 72" {...props}>
    <path
      d="M31 0v31H0v10h31v31h10V41h31V31H41V0z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
)

export default SvgComponent
