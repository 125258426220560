import { createGlobalStyle } from "styled-components"
import media from "styled-media-query"

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: "Pilowlava";
    src: url("/fonts/pilowlava/Pilowlava-Regular.woff2");
  }
  
  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  
  body {
    margin: 0;
    font-family: 'Arial', serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
    font-weight: 400;
    color: white;

    ${media.greaterThan("small")`
      font-size: 24px;
    `}
  }

  h1 {
    font-size: 2em;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Pilowlava', serif;
    font-weight: 400;
  }
  
  h1, h2 {
    margin-top: 0;
    font-weight: 400;
  }

  h3 {
    margin: 0;
  }

  a {
    color: currentColor;
  }
`

export default GlobalStyles
