import React from "react"
import styled, { keyframes } from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

const marquee = keyframes`
  from {
    background-position: 0;
  }
  to {
    background-position: -340px;
  }
`

const Outline = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  height: 40px;
  width: 100%;
  background: black;
  animation: ${marquee} 10s infinite linear;
  z-index: 10000;
`

function POTGBanner() {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "potg-banner-2x.png" }) {
        childImageSharp {
          fixed(quality: 100, height: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <>
      <Outline
        style={{
          top: 0,
          background: `url(${data.placeholderImage.childImageSharp.fixed.src})`,
          backgroundSize: "contain",
        }}
      />
      <Outline
        style={{
          bottom: 0,
          background: `url(${data.placeholderImage.childImageSharp.fixed.src})`,
          backgroundSize: "contain",
        }}
      />
    </>
  )
}

export default POTGBanner
