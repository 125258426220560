import React from "react"
import styled from "styled-components"
import { GatsbyLink, DefaultLink as A } from "./link"
import Plus from "../icons/plus"
import Minus from "../icons/minus"
import media from "styled-media-query"

export const MENU_SIZE = 60

const MenuContainer = styled.aside`
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100%;
  background: black;
  transform: ${({ open }) => (open ? `translateX(0)` : `translateX(100%)`)};
  transition: transform 0.25s ease;
  z-index: 1000;
  color: white;
  overflow: scroll;

  ${media.greaterThan("small")`
    width: 400px;
  `}
`

const OpenButtonContainer = styled.div`
  position: fixed;
  right: 0.5rem;
  bottom: calc(40px + 0.5rem);
  z-index: 10000;
`

const OpenButton = styled.button`
  paddingTop: 0;
  paddingRight: 0;
  paddingBottom: 0;
  paddingLeft: 0;
  borderTopWidth: 0;
  borderRightWidth: 0;
  borderBottomWidth: 0;
  borderLeftWidth: 0;
  backgroundColor: 'transparent';
  fontStyle: 'inherit';
  fontWeight: 'inherit';
  fontSize: 'inherit';
  lineHeight: 'inherit';
  fontFamily: 'inherit';

  font-size: 1.5em;
  background: transparent;
  padding: 0.5rem;
  border 0;
  outline: 0;
  color: white;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }

  & > svg {
    transform: rotate(0);
    transition: transform 0.5s ease-in-out;
  }
`

const MenuContent = styled.nav`
  font-family: "Arial", serif;
  font-weight: 800;
  padding: 4rem 2rem;
  font-size: 1.5em;
  line-height: 1.25;
  text-transform: uppercase;
  min-height: 100vh;
  color: ${props => props.color};
  min-height: calc(100% - 8rem);
  padding-right: 60px;
`

export const MenuGroup = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
`

const Link = styled(GatsbyLink)`
  &.active {
    font-style: italic;
    text-decoration: underline;
  }
`

function Menu() {
  const [menuOpen, setMenuOpen] = React.useState(false)
  const toggleMenu = () => setMenuOpen(!menuOpen)
  const closeMenu = () => setMenuOpen(false)

  return (
    <>
      <OpenButtonContainer>
        <OpenButton onClick={toggleMenu}>
          {menuOpen ? <Minus /> : <Plus />}
        </OpenButton>
      </OpenButtonContainer>
      <MenuContainer open={menuOpen}>
        <MenuContent>
          <MenuGroup>
            <Link to="/" activeClassName="active" onClick={closeMenu}>
              Home
            </Link>
            <Link to="/about" activeClassName="active" onClick={closeMenu}>
              About
            </Link>
            <Link to="/contact" activeClassName="active" onClick={closeMenu}>
              Contact
            </Link>
          </MenuGroup>
          <MenuGroup>
            <A href="https://www.facebook.com/tINI/" target="_blank">
              Facebook
            </A>
            <A
              href="https://www.instagram.com/tinicouldbeworse/"
              target="_blank"
            >
              Instagram
            </A>
            <A href="https://tinimusic.bandcamp.com/" target="_blank">
              Bandcamp
            </A>
          </MenuGroup>
        </MenuContent>
      </MenuContainer>
    </>
  )
}

export default Menu
